<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item
          label="分组名称"
          prop="group"
          v-bind="formItemLayout"
      >
        <!--        <a-input v-model="form.group" v-decorator="['group']" placeholder="标签分组">-->
        <!--        </a-input>-->
        <!--        <a-radio-group v-decorator="['group',{initialValue: '企业'}]" v-model="form.group" :disabled="mode=='update'">-->
        <!--            <a-radio value="企业">企业</a-radio>-->
        <!--            <a-radio value="个人">个人</a-radio>-->
        <!--        </a-radio-group>-->
        <a-select v-model="form.typeGroup" v-decorator="['group']" @change="dimChanged">
          <a-select-opt-group v-for="(optGroup,ind) in dimensionList" :key="ind">
            <span slot="label">-----{{ optGroup.dimType| dictName("dimType") }}------</span>
            <a-select-option v-for="one in optGroup.list" :key="one.dimName" :value="optGroup.dimType+'-'+one.dimName">
              {{ optGroup.dimType| dictName("dimType") }} - {{ one.dimName }}
            </a-select-option>
          </a-select-opt-group>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
          label="分组状态"
          prop="status"
          v-bind="formItemLayout"
      >
        <a-radio-group v-decorator="['status',{initialValue: 1}]" v-model="form.status">
          <a-radio :value="1">有效</a-radio>
          <a-radio :value="0">无效</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="标签" v-bind="formItemLayout">
        <a-textarea v-model="form.goodTag" v-decorator="['goodTag']" placeholder="多个标签用逗号或换行分隔"
                    style="height:200px;">
        </a-textarea>
      </a-form-model-item>
<!--      <a-form-model-item label="批评标签" v-bind="formItemLayout">-->
<!--        <a-textarea v-model="form.badTag" v-decorator="['badTag']" placeholder="多个标签用逗号或换行分隔"-->
<!--                    style="height:200px;">-->
<!--        </a-textarea>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="排序序号" v-bind="formItemLayout">
        <a-input-number v-model="form.sortNumber" v-decorator="['sortNumber']" placeholder="排序序号，越大越优先">
        </a-input-number>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {DICT} from "@/utils/dict.js";
import helper from "@/utils/helper";

export default {
  name: "ModifySysTagModal",
  data() {
    return {
      dict: DICT,
      formItemLayout: {
        labelCol: {span: 4},
        wrapperCol: {span: 18},
      },
      mode: 'new',
      title: "",
      visible: false,
      confirmLoading: false,
      dimensionList: [],
      form: {},
      rules: {
        group: [
          {
            required: true,
            message: "分组不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.$get('/dimension/list', {pageSize: 2000}).then(res => {
      let list = [];
      ((res.data && res.data.records) || []).map(v => {
        let exists = list.find(ex => ex.dimType == v.dimType)
        if (!exists) {
          exists = {
            dimType: v.dimType, list: []
          }
          list.push(exists)
        }
        exists.list.push(v);
      })
      list.sort((a, b) => {
        return a.dimType - b.dimType
      });
      this.dimensionList = list;
    })
  },
  methods: {
    showModal(record, mode) {
      this.mode = mode || 'new'
      this.visible = true;
      if (this.mode === 'update') {
        this.title = "修改系统标签ID：" + record.id;
        this.form = {
          ...record,
          typeGroup: this.$helper.getDictName(record.dimType, "dimType") + '-' + record.group
        };
      } else {
        this.title = "新增系统标签"
        this.form = {}
      }
    },
    dimChanged(value) {
      let pos = value.indexOf('-');
      this.form.dimType = value.substring(0, pos)
      this.form.group = value.substring(pos + 1)
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid, values) => {
        if (valid) {
          this.confirmLoading = true;
          if (this.form.goodTag) {
            this.form.goodTag = this.form.goodTag.split(/[,，；、\r\n]/g).join(',')
          }
          if (this.form.badTag) {
            this.form.badTag = this.form.badTag.split(/[,，；、\r\n]/g).join(',')
          }
          this.$postJson("sysTag/save", this.form).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("修改成功");
            this.$emit("success");
          }).catch(() => {
            this.confirmLoading = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
